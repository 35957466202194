table.calendar-table {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}

table.calendar-table > tbody > tr > td {
    border: 1px solid black;
    border-collapse: collapse;
    vertical-align: top;
    width: 14%;
}

table.calendar-table > tbody > tr:nth-child(odd) > td.today {
    background-color: #1976d230;
}

table.calendar-table > tbody > tr:nth-child(odd) > td {
    height: 8em;
}

table.calendar-table > tbody > tr:nth-child(even) > td {
    background-color: #ddd;
}

table.calendar-table > thead > tr > th {
    border: 1px solid black;
    border-collapse: collapse;    
}

.calendar-table-day {
    position: relative;
    height: 100%;
}

.calendar-table-date {
    text-align: right;
}

.calendar-table-content {
    text-align: left;
    padding: 3px;
}

.calendar-table-lunar-day {
    background-color: lightgray;
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 3px;
    width: 100%;
}

.today {
    font-weight: bold !important;
}

.holiday {
    color: crimson !important;
}